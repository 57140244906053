import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  TabContent,  TabPane,  NavLink,  NavItem,  Nav, Alert,  FormGroup, Label, Input, CardHeader, Button, InputGroup} from "reactstrap";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../CONFIG.json';
import Select, { ActionMeta, OnChangeValue } from "react-select";
import CreatableSelect from 'react-select/creatable';
import DatePicker, { registerLocale, setDefaultLocale  } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { showLoader, isEmpty, actualizaObjeto, buildBody, isNumber } from '../../auxiliares/funciones';
import { timeFromDate, dayNameFromDate, monthNameFromDate, datetimeToStringDatetime } from '../../auxiliares/dates';

import { connect } from "react-redux";

registerLocale('es', es)

class Partido extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            partido:{},
            jugadores:[],
            campo:'equipo',
            orden:'menor'
        }
    }

    componentDidMount(){
        showLoader(true);
        this.obtienePartido(this.state.id)
    }

    obtienePartido = (id) =>{
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{id:id}))
      fetch(CONFIG.api + "partido/detalle",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            if(result.data.partido.fecha.length>10){
              result.data.partido.fecha = result.data.partido.fecha.substring(0,10)
            }
            this.setState({partido: result.data.partido, jugadores: result.data.jugadores})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    actualizaJugadorInfo = (input,id) =>{
      const campo = input.target.name
      const valor = input.target.value
      let res = this.state.jugadores
      const ind = res.findIndex(e => e.id === id)

      res[ind][campo] = valor
      this.setState({jugadores:res})
    }

    actualizaJugador = (input,id) =>{
      showLoader(true);
      let obj = { id:id }
      obj[input.target.name] = input.target.value
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,obj))
      fetch(CONFIG.api + "partido/cierre/jugador",{
                method: 'PUT',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
             this.actualizaJugadorInfo(input,id)
             /*confirmAlert({
                title: "Modificado!",
                buttons: [{label: 'Ok'}]
              })*/
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    ordenarTabla = () =>{
      const { campo, orden } = this.state
      let jugadores = this.state.jugadores
      if(orden === 'mayor'){
          jugadores.sort((a,b) => a[campo].localeCompare(b[campo]))
      }else{
          jugadores.sort((a,b) => b[campo].localeCompare(a[campo]))
      }
      return jugadores
    }

    render() {
        const { partido } = this.state
        const jugadores = this.ordenarTabla()
        let numColor = 0
        let color = ''

        return (
            <React.Fragment>
                {!isEmpty(partido) &&
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Cerrar Partido</h4>
                                </CardHeader>
                                    <CardHeader style={{backgroundColor: '#517192', color: 'white'}}>
                                      <h4 className="mb-0">{partido.tipo+' ('+partido.lugar+') - '+dayNameFromDate(partido.fecha)+' de '+monthNameFromDate(partido.fecha)+' - '+partido.hora}</h4>
                                    </CardHeader>
                                <CardBody>
                                  { isEmpty(jugadores) &&
                                    <Alert color="info"><i className="dripicons-information"></i> No tienes ningun jugador añadido.</Alert>
                                  }
                                  <Link to={'/partido/'+partido.id} className="mr-1 btn btn-info waves-effect waves-light">Ver partido</Link>
                                  <div className="table-responsive mt-4" style={{paddingBottom:'100px'}}>

                                    <Table className="table table-hover mb-0 table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th></th>
                                                <th>Nombre</th>
                                                <th>Telefono</th>
                                                <th style={{textAlign: 'center'}}>
                                                  <Button  onClick={()=>this.setState({campo:'equipo',orden:'menor'})} color={ this.state.orden === 'menor' ? 'info' : 'light'} className="waves-effect waves-light" type="button"><i className="dripicons-arrow-down"></i></Button>
                                                  Color/Equipo
                                                  <Button  onClick={()=>this.setState({campo:'equipo',orden:'mayor'})} color={ this.state.orden === 'mayor' ? 'info' : 'light'} className="waves-effect waves-light" type="button"><i className="dripicons-arrow-up"></i></Button>
                                                </th>
                                                <th>Asistencia</th>
                                                <th>Pagado</th>
                                                <th>Otros</th>
                                                <th style={{textAlign: 'center'}}>Ver jugador</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {jugadores.map((g,id)=>{
                                              if(color === '' && color !== g.equipo){
                                                color = g.equipo
                                                numColor = 1
                                              }else if(color !== '' && color !== g.equipo){
                                                color = g.equipo
                                                numColor = 1
                                              }else{
                                                numColor++
                                              }
                                              return <tr key={id}>
                                                          <td>{numColor}</td>
                                                          <td style={{fontWeight: 'bold'}}>{g.nombre}</td>
                                                          <td style={{textAlign: 'center', fontWeight: 'bold'}}>{g.telefono}</td>
                                                          <td style={{textAlign: 'center'}}>
                                                            <Button onClick={()=>this.actualizaJugador({target:{name:'equipo',value:g.equipo === 'Blanco' ? "" : "Blanco"}},g.id)} color={g.equipo === 'Blanco' ? "success" : "secondary"} className="waves-effect waves-light" type="button">B</Button>
                                                            <Button onClick={()=>this.actualizaJugador({target:{name:'equipo',value:g.equipo === 'Negro' ? "" : "Negro"}},g.id)} color={g.equipo === 'Negro' ? "success" : "secondary"} className="waves-effect waves-light" type="button" style={{marginLeft:'10px'}}>N</Button>
                                                            {/*<InputGroup>
                                                              <Input className="form-control" value={g.equipo} name="equipo" onChange={(e)=>this.actualizaJugadorInfo(e,g.id)} type="text"/>
                                                              <div className="input-group-append">
                                                                <Button onClick={()=>this.actualizaJugador({target:{name:'equipo',value:g.equipo}},g.id)} color="success" className="waves-effect waves-light" type="button"><i className="fas fa-save"></i></Button>
                                                              </div>
                                                            </InputGroup>*/}
                                                          </td>
                                                          <td style={{textAlign: 'center'}}><Button onClick={()=>this.actualizaJugador({target:{name:'asistencia',value:g.asistencia === 0 ? 1 : 0}},g.id)} color={g.asistencia === 1 ? "success" : "danger"} className="waves-effect waves-light" type="button">{g.asistencia === 1 ? "Asistido" : "Falta"}</Button></td>
                                                          <td style={{textAlign: 'center'}}><Button onClick={()=>this.actualizaJugador({target:{name:'pagado',value:g.pagado === 0 ? 1 : 0}},g.id)} color={g.pagado === 1 ? "success" : "danger"} className="waves-effect waves-light" type="button">{g.pagado === 1 ? "Pagado" : "Falta"}</Button></td>
                                                          <td style={{textAlign: 'center'}}>
                                                            <InputGroup>
                                                              <Input className="form-control" value={g.notas} name="notas" onChange={(e)=>this.actualizaJugadorInfo(e,g.id)} type="text"/>
                                                              <div className="input-group-append">
                                                            		<Button onClick={()=>this.actualizaJugador({target:{name:'notas',value:g.notas}},g.id)} color="success" className="waves-effect waves-light" type="button"><i className="fas fa-save"></i></Button>
                                                            	</div>
                                                            </InputGroup>
                                                          </td>
                                                          <td style={{textAlign: 'center'}}>{isNumber(g.idjugador) && <Link to={'/jugador/'+g.idjugador} className="mr-1 btn btn-info waves-effect waves-light"><i className="dripicons-user"></i></Link>}</td>
                                                      </tr>
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  }
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { })(Partido));
