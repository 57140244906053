import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody,Alert, Button } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import logodark from "../assets/images/logo-dark.png";
import CONFIG from '../CONFIG.json';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { workWithLocalStorage, showLoader,getLoggedInUser } from '../auxiliares/funciones';

class Pageslogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            username_: "cristian",
            password_: "2021cristian",
            btnBlocked:false,
            user:{
              token:'134635645613',
              nombre:'Cristian'
            }
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (event, values) => {
      this.setState({btnBlocked:true})
       showLoader(true);
       if(values.username.toLowerCase() === this.state.username_ && values.password.toLowerCase() === this.state.password_){
         let result = this.state.user
         if(document.getElementById('remember').checked){
           result.r = true
         }
         workWithLocalStorage('set','user',result)
         window.location = '/app'
       }else{
         showLoader(false);
         confirmAlert({
            title: "Datos de acceso incorrectos",
            buttons: [{label: 'Ok'}]
          })
         this.setState({btnBlocked:false})
       }
    }

    /*componentWillMount(){
      const ses = getLoggedInUser()
      if(ses != null && ses.r !== undefined){
        //showLoader(true);
      }
    }*/

    componentDidMount(){
      const ses = getLoggedInUser()
      if(ses != null && ses.r !== undefined){
        showLoader(true);
        this.setState({btnBlocked:true})
        if(ses.token === this.state.user.token){
        showLoader(false);
        const caducada = this.props.location.state ? this.props.location.state.caducada : false
        if(caducada){
          this.props.history.push({pathname: this.props.location.state.url})
        }else{
          window.location = '/app'
        }
      }else{
        confirmAlert({
           title: "Datos de acceso incorrectos",
           buttons: [{label: 'Ok'}]
         })
        this.setState({btnBlocked:false})
      }
    }else{
      if(ses != null){
        workWithLocalStorage('remove','user',null)
      }
        if(this.props.history.location.pathname !== '/app'){
          showLoader(false)
        }
    }
  }

    render() {
        const logout = this.props.location.state ? this.props.location.state.logout : false
        const caducada = this.props.location.state ? this.props.location.state.caducada : false
        return (
            <React.Fragment>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md="8" lg="6" xl="5">
                                <Card className="overflow-hidden">
                                    <CardBody className="pt-0">
                                        <h3 className="text-center mt-4">
                                            <Link to="\" className="logo logo-admin"><img className="originalSize" src={logodark} alt="logo" /></Link>
                                        </h3>
                                        <div className="p-3">
                                        <h4 className="text-muted font-size-18 mb-1 text-center">{CONFIG.nombre}</h4>
                                        <p className="text-muted text-center">{CONFIG.descripcion}</p>
                                        {logout && <Alert color="success">Has cerrado sesión de forma correcta</Alert>}
                                        {caducada && <Alert color="warning">Tu sesión ha caducado, vuelve a acceder por favor.</Alert>}
                                            <AvForm className="form-horizontal mt-4" onValidSubmit={this.handleSubmit}>

                                                <label htmlFor="username">Usuario</label>
                                                <AvField name="username" placeholder="Introduce tu usuario facilitado" value={ this.state.username }  type="text" />

                                                <label htmlFor="userpassword">Contraseña</label>
                                                <AvField name="password" type="password" value={ this.state.password } placeholder="Introduce tu contraseña" />

                                                <div className="form-group row mt-4">
                                                    <Col xs="6">
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox" className="custom-control-input" id="remember" />
                                                            <label className="custom-control-label" htmlFor="remember">¿Recordarme?</label>
                                                        </div>
                                                    </Col>
                                                    <Col xs="6" className="text-right">
                                                        { this.state.btnBlocked ?
                                                        <Button color="primary" disabled className="w-md waves-effect waves-light" type="submit">Accediendo...</Button>
                                                        : <Button color="primary" className="w-md waves-effect waves-light" type="submit">Acceder</Button>
                                                        }
                                                    </Col>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { user, loginError, loading } = state.Login;
    return { user, loginError, loading };
}

export default withRouter(connect(mapStatetoProps, {})(Pageslogin));
