import React from "react";
import { Redirect } from "react-router-dom";

import Jugadores from "../paginas/jugadores/listado";
import Jugador from "../paginas/jugadores/jugador";

import Partidos from "../paginas/partidos/listado";
import Historico from "../paginas/partidos/historico";
import Partido from "../paginas/partidos/detalle";
import CierrePartido from "../paginas/partidos/cierre";

import Asistencias from "../paginas/estadisticas/asistencias";
import Faltas from "../paginas/estadisticas/faltas";
import Deudas from "../paginas/estadisticas/deudas";

import Pageslogin from '../paginas/login';
import Logout from '../paginas/logout';

import E404 from "../paginas/404";
import E500 from "../paginas/500";

const authProtectedRoutes = [

  { exact: true, path: "/app", component: Partidos, profiles:[1] },


  { exact: true, path: "/jugadores", component: Jugadores , name:'Jugadores', profiles:[1], icon:'mdi mdi-account-supervisor-outline',menu:'Jugadores'},
  { exact: true, path: "/jugador", component: Jugador , name:'Crear Jugador', profiles:[1], icon:'mdi mdi-account-multiple-plus-outline',menu:'Jugadores'},
  { exact: true, path: "/jugador/:id", component: Jugador , name:'Detalle Jugador', profiles:[1], icon:'mdi mdi-account-multiple-plus-outline'},

  { exact: true, path: "/partidos", component: Partidos , name:'Partidos', profiles:[1], icon:'mdi mdi-clipboard-list-outline', menu:'Partidos'},
  { exact: true, path: "/historico", component: Historico , name:'Histórico', profiles:[1], icon:'mdi mdi-clipboard-list', menu:'Partidos'},
  { exact: true, path: "/partido", component: Partido , name:'Crear Partido', profiles:[1], icon:'mdi mdi-clipboard-list', menu:'Partidos'},
  { exact: true, path: "/partido/:id", component: Partido , name:'Detalle Partido', profiles:[1], icon:'mdi mdi-clipboard-list'},
  { exact: true, path: "/partido-cierre/:id", component: CierrePartido , name:'Cierre Partido', profiles:[1], icon:'mdi mdi-clipboard-list'},

  { exact: true, path: "/asistencias", component: Asistencias , name:'Asistencias', profiles:[1], icon:'mdi mdi-calendar-weekend-outline', menu:'Estadísticas'},
  { exact: true, path: "/faltas", component: Faltas , name:'Faltas', profiles:[1], icon:'mdi mdi-account-badge-horizontal-outline', menu:'Estadísticas'},
  { exact: true, path: "/deudas", component: Deudas , name:'Deudas', profiles:[1], icon:'mdi mdi-cash', menu:'Estadísticas'},

  //OTROS 2
  { exact: true, path: "/500", component: E500 ,name:'Error 500', profiles:[1]},
  { exact: true, path: "/",  component: () => <Redirect to="/app" /> ,profiles:[1]}
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Pageslogin },
  { path: "/404", component: E404 },
  { exact: true, path: "/",  component: () => <Redirect to="/login" />}
];

export { authProtectedRoutes, publicRoutes };
