export const workWithLocalStorage = (action,key,value) =>{
    if (window.localStorage) {
        if(action==='set'){
            localStorage.setItem(key, JSON.stringify(value));
            return "";
        }else if(action==='get'){
            return JSON.parse(localStorage.getItem(key));
        }else if(action==='remove'){
            localStorage.removeItem(key);
            return "";
        }
    }
}

export const buildBody = (token,obj) =>{
    return {
            "data": {
                "API_TOKEN":"uj345345krwyfgERGETBERBVetb457345hwertbWRTBRYJRTHER756756GWEGR",
                "obj":obj
            }
          }
    }

//

export const htmlToText = (html) =>{
  /*html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
  html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
  html = html.replace(/<\/div>/ig, '\n');
  html = html.replace(/<\/li>/ig, '\n');
  html = html.replace(/<li>/ig, '  *  ');
  html = html.replace(/<\/ul>/ig, '\n');
  html = html.replace(/<\/p>/ig, '\n');
  html = html.replace(/<br>/gi, "\n");
  html = html.replace(/<br\/>/gi, "\n");
  html = html.replace(/<br \/>/gi, "\n");
  html = html.replace(/<[^>]+>/ig, '');
  return html*/

  const { htmlToText } = require('html-to-text');
  return htmlToText(html, {});
}

export const isEmpty = (obj) => {
  if(obj === undefined || obj === null){
    return true;
  }else{
    return Object.keys(obj).length === 0;
  }
}

export const compareArrays = (x,y)=>{
   return x.length === y.length && x.every((o, idx) => compareObjects(o, y[idx]));
}

export const compareObjects = (x,y)=>{
  return typeof x === 'object' && Object.keys(x).length > 0
        ? Object.keys(x).length === Object.keys(y).length
            && Object.keys(x).every(p => compareObjects(x[p], y[p]))
        : x === y;
}

export const actualizaObjeto = (obj,data) =>{
  const keys = Object.keys(data);
  keys.forEach((key, index) => {
    obj[key] = data[key]
  });
  return obj
}

export const getProfile = () =>{
  const user= getLoggedInUser()
  let profile = user.token.charAt(0)
  return parseInt(profile)
}

export const getLoggedInUser = () => {
        if(!workWithLocalStorage('get','user','')) return null;
        return workWithLocalStorage('get','user','');
}

export const getCourseInfo = () => {
        if(!workWithLocalStorage('get','course','')) return null;
        return workWithLocalStorage('get','course','');
}

export const getEvents = () => {
        if(!getCourseInfo()) return null;
        return getCourseInfo().fechasChat.split(',');
}

export const isNumber = value => {
    if (isNaN(value)) {
        return false
    } else {
        return true
    }
}

//is user is logged in
export const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
}

export const showLoader = (mostrar) =>{
  if(mostrar)
    {
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";
    }else{
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    }
}

export const compruebaComienzoPalabra = (a,b,palabra) =>{
    const letras = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "ñ", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]
    var inicio = false
        var ini = letras.findIndex(l => l === a)
        var fin = letras.findIndex(l => l === b)
        for(var i = ini; i<fin;i++){
            let letra = letras[i]
            if (letra === a && !inicio) {
                inicio = true
            }
            if (palabra.toLowerCase().startsWith(letra)) {
                return true
            }
            if (letra === "a") {
                if (palabra.toLowerCase().startsWith("á") || palabra.toLowerCase().startsWith("à")) {
                    return true
                }
            }
            if (letra === "e") {
                if (palabra.toLowerCase().startsWith("é") || palabra.toLowerCase().startsWith("è")) {
                    return true
                }
            }
            if (letra === "i") {
                if (palabra.toLowerCase().startsWith("í") || palabra.toLowerCase().startsWith("ì")) {
                    return true
                }
            }
            if (letra === "o") {
                if (palabra.toLowerCase().startsWith("ó") || palabra.toLowerCase().startsWith("ò")) {
                    return true
                }
            }
            if (letra === "u") {
                if (palabra.toLowerCase().startsWith("ú") || palabra.toLowerCase().startsWith("ù")) {
                    return true
                }
            }
            if (letra === b) {
                return false
            }
        }
        return false
}
