import React, { Component } from 'react';
import { Container } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {  Col,   Row, Alert } from "reactstrap";

import CONFIG from '../../CONFIG.json';
import { getLoggedInUser } from '../../auxiliares/funciones';
import Navbar from "./Navbar";
import Topbar from "./Topbar";

class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpened: false,
            error_dlg: false,
            error: '',
            espera: false
        };
    }

    componentDidMount() {
        //showLoader(true);
        window.scrollTo(0, 0);
        document.title = CONFIG.nombre +" | "+CONFIG.descripcion;
        document.body.setAttribute("data-layout", "horizontal");
    }

    openMenu = e => {
        this.setState({ isMenuOpened: !this.state.isMenuOpened });
    };

    render() {
        const ses = getLoggedInUser()

        return (
            <React.Fragment>
                <div id="preloader">
                    <div id="status">
                        <div className="spinner-chase">
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                            <div className="chase-dot"></div>
                        </div>
                    </div>
                </div>
                <div id="layout-wrapper">
                    <header id="page-topbar">
                        <Topbar isMenuOpened={this.state.isMenuOpened} openLeftMenuCallBack={this.openMenu.bind(this)}/>
                        <div className="top-navigation">
                            <Navbar menuOpen={this.state.isMenuOpened} />
                        </div>
                    </header>
                    <div className="main-content">
                        <div className="page-content_ noMarginTop_ noPaddingTop_" style={{paddingTop: '170px'}}>
                            <Container fluid>
                              {!this.state.espera &&
                                (this.state.error_dlg ?
                                  <Row>
                                      <Col md="12" lg="12" xl="12">
                                      {this.state.error_dlg &&<Alert color="danger"><i className="dripicons-warning"></i> {this.state.error}</Alert>}
                                      </Col>
                                  </Row>
                                : this.props.children)
                              }
                            </Container>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { isPreloader } = state.Layout;
      return { isPreloader };
};

export default withRouter(connect(mapStatetoProps, {})(Layout));
