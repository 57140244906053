import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  TabContent,  TabPane,  NavLink,  NavItem,  Nav, Alert,  FormGroup, Label, Input, CardHeader, Button} from "reactstrap";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import CONFIG from '../../CONFIG.json';
import { showLoader, buildBody, isEmpty } from '../../auxiliares/funciones';
import { timeFromDate, dayNameFromDate, monthNameFromDate } from '../../auxiliares/dates';

import { connect } from "react-redux";

class Deudas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jugadores:[],
            jugadoresO:[]
        }
    }

    componentDidMount(){
        showLoader(true);
        this.obtieneJugadores()
    }

    buscaTabla = (input) =>{
      const datos = this.state.jugadoresO.filter(item => {
        return Object.keys(item).some(key => {
          return key !== 'id' && item[key].toString().toLowerCase().includes(input.target.value.toLowerCase())
        }
        );
      })
      this.setState({jugadores: datos})
    }

    obtieneJugadores = () =>{
      showLoader(true);
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
      fetch(CONFIG.api + "deudas",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            this.setState({jugadores: result.data, jugadoresO: result.data})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    render() {
        const { jugadores } = this.state
        jugadores.sort((a,b) => a.deudas<b.deudas)

        return (
            <React.Fragment>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Deudas</h4>
                                </CardHeader>
                                <CardBody>
                                  <div className="table-responsive mt-4">
                                    <FormGroup>
                                        <Label className="control-label">Buscar</Label>
                                        <Input className="form-control width200" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e)} type="text" name="buscar"/>
                                    </FormGroup>
                                    <Table className="table table-hover mb-0 table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th style={{textAlign: 'center'}}>Total {jugadores.length} Deben</th>
                                                <th>Nombre</th>
                                                <th style={{textAlign: 'center'}}>Teléfono</th>
                                                <th style={{textAlign: 'center'}}>Deuda</th>
                                                <th style={{textAlign: 'center'}}>Partidos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {jugadores.map((g,id)=>{
                                              return <tr key={id}>
                                                            <td style={{textAlign: 'center'}}><Link to={'/jugador/'+g.id} className="mr-1 btn btn-info waves-effect waves-light">Ver jugador</Link></td>
                                                            <td>{g.nombre}</td>
                                                            <td style={{textAlign: 'center'}}>{g.telefono}</td>
                                                            <td style={{textAlign: 'center'}}>{g.deudas} €</td>
                                                            <td style={{textAlign: 'center'}}>{g.partidos}</td>
                                                        </tr>
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { })(Deudas));
