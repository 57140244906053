import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Container } from "reactstrap";

//Import Menus
import LogoutButton from "../../componentes/cerrarsesion";


//Import Images
import logosm from "../../assets/images/logo-sm.png";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

class Topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSearch : false
        }
        this.toggleMenu.bind(this);
    }


    componentDidMount(){
        //set for temporary
        document.body.setAttribute("data-layout", "horizontal");
    }

    toggleMenu = () => {
        this.props.openLeftMenuCallBack();
    }

    render() {
        return (
            <React.Fragment>

            <div className="navbar-header">
                <Container fluid>
                    <div className="float-left">

                        <div className="navbar-brand-box">
                            <Link to="/app" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logosm} alt="" height="22"/>
                                </span>
                                <span className="logo-lg">
                                    <img src={logodark} alt=""/>
                                </span>
                            </Link>

                            <Link to="/app" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logosm} alt="" height="22"/>
                                </span>
                                <span className="logo-lg">
                                    <img src={logolight} alt="" height="19"/>
                                </span>
                            </Link>
                        </div>

                        <button type="button"
                            className="btn btn-sm px-3 font-size-24 d-lg-none header-item waves-effect waves-light"
                            onClick={this.toggleMenu}
                            data-toggle="collapse"
                            data-target="#topnav-menu-content"
                        >
                            <i className="mdi mdi-menu"></i>
                        </button>

                    </div>


                    <div className="float-right">
                      <LogoutButton/>
                    </div>
                </Container>
            </div>
            </React.Fragment>
        );
    }
}

export default Topbar;
