import React, { Component } from 'react';
import { workWithLocalStorage, showLoader } from '../auxiliares/funciones';

class Logout extends Component {

    constructor(props) {
        super(props);
        this.state = {  };
    }

    componentDidMount() {
      showLoader(true)
      workWithLocalStorage('remove','user','');
      this.props.history.push({
        pathname: '/login',
        search: '',
        state: { logout: true }
      });
    }

    render() {
        return (
            <React.Fragment>
               <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}


export default Logout;
