import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  Alert,  FormGroup, Label, Input, CardHeader, Button} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../CONFIG.json';
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { showLoader, isEmpty, buildBody } from '../../auxiliares/funciones';
import { dayNameFromDate, monthNameFromDate } from '../../auxiliares/dates';

import { connect } from "react-redux";

class Partido extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            partido:{
              fecha: new Date(),
              hora:'',
              tipo: '',
              lugar: '',
              idcampo: '',
              numequipos: '2',
              jugadores: '0',
              precio: ''
            },
            jugN:{},
            jugadores:[],
            jugadoresS:[],
            jugadoresSelect:[],
            campos:[],
            campos_form : ['fecha','hora','tipo','lugar','precio']
        }
    }

    componentDidMount(){
        showLoader(true);
        if(this.state.id !== undefined){
          this.obtienePartido(this.state.id)
        }else{
          this.obtieneCampos()
          this.obtieneJugadores()
        }
    }

    obtieneCampos = () =>{
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
      fetch(CONFIG.api + "campos",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            if(this.state.id !== undefined){
              const partido = this.state.partido
              let ind = result.data.findIndex(e => e.value === partido.idcampo)
              if(ind<0){
                result.data.push({value: partido.idcampo, label: partido.lugar})
              }
            }
            this.setState({campos: result.data})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    obtieneJugadores = () =>{
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
      fetch(CONFIG.api + "jugadores/select",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            this.setState({jugadoresS: result.data},() => {this.filtraJugadores()})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          if(this.state.id === undefined){
            showLoader(false);
          }
      })
    }

    obtienePartido = (id) =>{
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{id:id}))
      fetch(CONFIG.api + "partido/detalle",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            if(result.data.partido.fecha.length>10){
              result.data.partido.fecha = result.data.partido.fecha.substring(0,10)
            }
            this.setState({partido: result.data.partido, jugadores: result.data.jugadores},() => {
              this.obtieneCampos()
              this.obtieneJugadores()
            })
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    actualizaCampo = (input) =>{
      const nombre = input.target.name
      const respuesta = input.target.value
      let res = this.state.partido
      res[nombre] = respuesta
      this.setState({partido:res})
    }

    actualizaCampoSelect = (nombre,respuesta) =>{
      let res = this.state.partido
      res[nombre] = respuesta
      this.setState({partido:res})
    }

    actualizaCampos = (newValue: any, actionMeta: any) => {
      let partido = this.state.partido
      if(newValue){
        if(newValue.value === newValue.label){//Nuevo
          partido.idcampo = 'T'+new Date().getTime()
          partido.lugar = newValue.label
          let campos = this.state.campos
          campos.push({value: partido.idcampo, label: partido.lugar})
          this.setState({campos: campos})
        }else{//De la lista
          partido.idcampo = newValue.value
          partido.lugar = newValue.label
        }
      }else{
        partido.idcampo = 0
        partido.campo = ''
      }
      console.log(partido)
      this.setState({partido:partido})
    };

    actualizaCamposJugador = (newValue: any, actionMeta: any) => {
      if(newValue){
        if(newValue.value === newValue.label){//Nuevo
          this.setState({jugN:{value:'T'+new Date().getTime(),
            label: newValue.label,
            nombre: newValue.label,
            telefono:'',
            grupo:''}})
          document.getElementById('jugador_telefono').value = ''
          document.getElementById('jugador_grupo').value = ''
        }else{//De la lista
          this.setState({jugN:newValue})
          document.getElementById('jugador_telefono').value = newValue.telefono
          document.getElementById('jugador_grupo').value = newValue.grupo
        }
      }else{
        this.setState({jugN:{}})
        document.getElementById('jugador_telefono').value = ''
        document.getElementById('jugador_grupo').value = ''
      }
    };

    grabarForm = () =>{
      let res = this.state.partido
      let camposOK = true
      this.state.campos_form.forEach((c, i) => {
        if(!res[c]){
          camposOK = false
        }
      });
      if(camposOK){
        showLoader(true);
         const partido = this.state.partido
         let part = {
           id: partido.id ? partido.id : null,
           fecha: partido.fecha+' '+partido.hora,
           tipo: partido.tipo,
           idcampo: partido.idcampo,
           campo: partido.lugar,
           numequipos: partido.numequipos,
           precio: partido.precio
         }
         const body = JSON.stringify(buildBody(CONFIG.TOKEN,part))
         fetch(CONFIG.api + "partido",{
                   method: 'PUT',
                   body: body,
                   headers: {'Content-Type': 'application/json'}
           })
           .then(res => res.json())
           .then((result) => {
             if (result.error === 0) {
               confirmAlert({
                  title: "Grabado!",
                  buttons: [{label: 'Ok'}]
                })
               if(!partido.id){
                 //this.setState({partido:actualizaObjeto(this.state.partido,result.data)})
                 this.props.history.push({pathname: '/partido/'+result.data.id});
               }
             } else{
               confirmAlert({
                  title: "Error",
                  message: result.errorMessage,
                  buttons: [{label: 'Ok'}]
                })
             }
             showLoader(false);
         })
    }else{
      confirmAlert({
         title: "Faltan datos",
         message: "Revisa que hayas respondido todas las cuestiones",
         buttons: [{label: 'Ok'}]
       })
    }
  }

    anadeJugador = () =>{
      if(this.state.jugN.label){
         showLoader(true);
         const jugN = this.state.jugN
         if(jugN.value.toString().startsWith('T')){
           confirmAlert({
              title: "¿Quieres crear al jugador?",
              buttons: [{
                    label: 'Si',
                    onClick: () => this.grabaJugador()
                  },
                  {
                    label: 'No',
                    onClick: () => this.anadeJugador2(0)
                  }
              ]
            })
         }else{
           this.anadeJugador2(0)
         }
    }else{
      confirmAlert({
         title: "Faltan datos",
         message: "Revisa que hayas rellenado al menos el nombre",
         buttons: [{label: 'Ok'}]
       })
    }
    }

    grabaJugador = () =>{
      const jugN = this.state.jugN
        let jug = {
          id: null,
          notas:'',
          nombre: jugN.nombre,
          telefono: jugN.telefono === '' ? this.state.partido.jugador_telefono : jugN.telefono,
          grupo: jugN.grupo === '' ? this.state.partido.jugador_grupo : jugN.grupo
        }
        const body = JSON.stringify(buildBody(CONFIG.TOKEN,jug))
        fetch(CONFIG.api + "jugador",{
                  method: 'PUT',
                  body: body,
                  headers: {'Content-Type': 'application/json'}
          })
          .then(res => res.json())
          .then((result) => {
            if (result.error === 0) {
              this.anadeJugador2(result.data.id)
            } else{
              confirmAlert({
                 title: "Error al crear jugador",
                 message: result.errorMessage,
                 buttons: [{label: 'Ok'}]
               })
            }
        })
    }

    anadeJugador2 = (id) =>{
         const jug = this.state.jugN
         const body = JSON.stringify(buildBody(CONFIG.TOKEN,{
           idpartido:this.state.id,
           idjugador: id !== 0 ? id : jug.value,
           nombre: jug.nombre,
           telefono: jug.telefono === '' ? this.state.partido.jugador_telefono : jug.telefono,
           grupo: jug.grupo === '' ? this.state.partido.jugador_grupo : jug.grupo
         }))
         fetch(CONFIG.api + "partido/jugador",{
                   method: 'PUT',
                   body: body,
                   headers: {'Content-Type': 'application/json'}
           })
           .then(res => res.json())
           .then((result) => {
             if (result.error === 0) {
                let jugs = this.state.jugadores
                let jugN = this.state.jugN
                jugN.idjugador =  id !== 0 ? id : jugN.value+''
                if(jugN.telefono === '' ) jugN.telefono = document.getElementById('jugador_telefono').value
                if(jugN.grupo === '' ) jugN.grupo = document.getElementById('jugador_grupo').value
                jugs.push(jugN);
                this.setState({jugadores:jugs,jugN:{}})
                document.getElementById('jugador_telefono').value = ''
                document.getElementById('jugador_grupo').value = ''
                this.filtraJugadores()
             } else{
               confirmAlert({
                  title: "Error",
                  message: result.errorMessage,
                  buttons: [{label: 'Ok'}]
                })
             }
             showLoader(false);
         })
    }

    quitaJugador = (id) =>{
           showLoader(true);
           const body = JSON.stringify(buildBody(CONFIG.TOKEN,{idpartido:this.state.id, idjugador:id}))
           fetch(CONFIG.api + "partido/jugador",{
                     method: 'DELETE',
                     body: body,
                     headers: {'Content-Type': 'application/json'}
             })
             .then(res => res.json())
             .then((result) => {
               if (result.error === 0) {
                  let jugs = this.state.jugadores
                  let jugsN = jugs.filter(j => j.idjugador !== id)
                  this.setState({jugadores:jugsN},() => {this.filtraJugadores()})
                  /*confirmAlert({
                     title: "Borrado del partido!",
                     buttons: [{label: 'Ok'}]
                   })*/
               } else{
                 confirmAlert({
                    title: "Error",
                    message: result.errorMessage,
                    buttons: [{label: 'Ok'}]
                  })
               }
               showLoader(false);
           })
    }

    filtraJugadores = () =>{
      const jugadores = this.state.jugadores
      let jugadoresS = this.state.jugadoresS
      jugadores.forEach(j => {
        jugadoresS = jugadoresS.filter(s => s.value.toString() !== j.idjugador);
      })
      //return jugadoresS.filter(jug => word.length > 6);
      this.setState({jugadoresSelect:jugadoresS})
    }

    render() {
        const {partido, jugadores } = this.state
        const futbol = [
          { label: "Futbol Sala", value: "Futbol Sala" },
          { label: "Futbol 7", value: "Futbol 7" },
          { label: "Futbol 8", value: "Futbol 8" },
          { label: "Futbol 11", value: "Futbol 11" },
          { label: "Torneo Futbol Sala", value: "Torneo Futbol Sala" },
          { label: "Torneo Futbol 7", value: "Torneo Futbol 7" },
          { label: "Torneo Futbol 8", value: "Torneo Futbol 8" },
          { label: "Torneo Futbol 11", value: "Torneo Futbol 11" }
        ]

        const defTipo = futbol.find(element => element.value === partido.tipo)
        const defCampo = this.state.campos.find(element => element.value === partido.idcampo)

        return (
            <React.Fragment>
                {!isEmpty(partido) &&
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader style={{backgroundColor: '#517192', color: 'white'}}>
                                  { partido.id ?
                                  <h4 className="mb-0">{partido.tipo+' ('+partido.lugar+') - '+dayNameFromDate(partido.fecha)+' de '+monthNameFromDate(partido.fecha)+' - '+partido.hora}</h4>
                                  : <h4 className="mb-0">Nuevo partido</h4>
                                  }
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                    <Col md="3" lg="3" xl="3" className="mt-4">
                                      <FormGroup>
                                          <Label className="control-label">Tipo</Label>
                                          <Select
                                              name="tipo"
                                              value = {defTipo}
                                              onChange={(e)=>this.actualizaCampoSelect('tipo',e.value)}
                                              options={futbol}
                                              className="select2"
                                          />
                                      </FormGroup>
                                    </Col>
                                    <Col md="3" lg="3" xl="3" className="mt-4">
                                      <FormGroup>
                                          <Label className="control-label">Campo</Label>
                                          {/*}<Select
                                              name="idcampo"
                                              value = {defCampo}
                                              onChange={(e)=>{
                                                this.actualizaCampoSelect('idcampo',e.value)
                                                this.actualizaCampoSelect('lugar',e.label)
                                              }}
                                              options={this.state.campos}
                                              className="select2"
                                          />*/}

                                          <CreatableSelect
                                            isClearable
                                            name="idcampo"
                                            onChange={this.actualizaCampos}
                                            options={this.state.campos}
                                            className="select2"
                                            value={defCampo}
                                          />
                                      </FormGroup>
                                    </Col>
                                      <Col md="3" lg="3" xl="3" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Fecha</Label><br/>
                                            <Input className="form-control" value={partido.fecha} onChange={(e)=>this.actualizaCampo(e)} type="date" name="fecha"/>
                                            {/*<DatePicker
                                                selected={partido.fecha ? new Date(partido.fecha) : new Date()}
                                                onChange={(date) => this.actualizaCampoSelect('fecha',datetimeToStringDatetime(date))}
                                                locale="es"
                                                showTimeSelect
                                                timeFormat="HH:mm"
                                                timeIntervals={15}
                                                dateFormat="dd/MM/yyyy HH:mm"
                                                className="form-control"
                                              />
                                            <Input className="form-control" onChange={(e)=>this.actualizaCampo(e)} type="text" name="fecha"/>*/}
                                        </FormGroup>
                                      </Col>
                                      <Col md="2" lg="2" xl="2" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Hora</Label><br/>
                                            <Input className="form-control" value={partido.hora} onChange={(e)=>this.actualizaCampo(e)} type="time" name="hora"/>
                                        </FormGroup>
                                      </Col>
                                      </Row>
                                      <Row>
                                        <Col md="2" lg="2" xl="2" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Nº Equipos</Label>
                                            <Input className="form-control" value={partido.numequipos} onChange={(e)=>this.actualizaCampo(e)} type="number" name="numequipos"/>
                                        </FormGroup>
                                      </Col>
                                        <Col md="2" lg="2" xl="2" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Precio</Label>
                                            <Input className="form-control" value={partido.precio} onChange={(e)=>this.actualizaCampo(e)} type="number" name="precio"/>
                                        </FormGroup>
                                      </Col>
                                        <Col md="2" lg="2" xl="2" className="mt-4"><br/>
                                          <Button onClick={()=>this.grabarForm()} color="success" className="w-md waves-effect waves-light" type="button">Grabar partido</Button>
                                      </Col>
                                      <Col md="2" lg="2" xl="2" className="mt-4"><br/>
                                          <Link to={'/partido/'} className="mr-1 btn btn-info waves-effect waves-light">Nuevo partido</Link>
                                      </Col>
                                      {partido.id &&
                                      <Col md="2" lg="2" xl="2" className="mt-4"><br/>
                                          <Link to={'/partido-cierre/'+partido.id} className="mr-1 btn btn-warning waves-effect waves-light">Cerrar partido</Link>
                                      </Col>
                                      }
                                    </Row>
                                </CardBody>
                                <CardHeader>
                                  <h4 className="mb-0">Lista de jugadores { !isEmpty(jugadores) && '('+jugadores.length+')' }</h4>
                                </CardHeader>
                                <CardBody>
                                  { isEmpty(jugadores) &&
                                    <Alert color="info"><i className="dripicons-information"></i> No tienes ningun jugador añadido.</Alert>
                                  }
                                  { this.state.partido.id &&
                                  <div className="table-responsive mt-4" style={{paddingBottom:'100px'}}>
                                    <Table className="table table-hover mb-0 table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Telefono</th>
                                                <th style={{textAlign: 'center'}}>Grupo</th>
                                                <th style={{textAlign: 'center'}}>Ver jugador</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                              <td>
                                                <CreatableSelect
                                                  isClearable
                                                  onChange={this.actualizaCamposJugador}
                                                  options={this.state.jugadoresSelect}
                                                  className="select2"
                                                  value={this.state.jugN}
                                                />
                                              </td>
                                              <td style={{textAlign: 'center'}}>
                                                <FormGroup>
                                                    <Input className="form-control" onChange={(e)=>this.actualizaCampo(e)} type="number" name="jugador_telefono" id="jugador_telefono"/>
                                                    <Input type="hidden" id="jugador_id"/>
                                                </FormGroup>
                                              </td>
                                              <td style={{textAlign: 'center'}}>
                                                <FormGroup>
                                                    <Input className="form-control" onChange={(e)=>this.actualizaCampo(e)} type="text" name="jugador_grupo" id="jugador_grupo"/>
                                                </FormGroup>
                                              </td>
                                              <td style={{textAlign: 'center'}}><Button onClick={()=>this.anadeJugador()} color="success" className="waves-effect waves-light" type="button">+</Button></td>
                                          </tr>
                                          {jugadores.map((g,id)=>{
                                              return <tr key={id}>
                                                          <td style={{fontWeight: 'bold'}}>{g.nombre}</td>
                                                          <td style={{textAlign: 'center', fontWeight: 'bold'}}>{g.telefono}</td>
                                                          <td style={{textAlign: 'center'}}>{g.grupo} <Button onClick={()=>this.quitaJugador(g.idjugador)} color="danger" className="waves-effect waves-light" type="button"><i className="dripicons-trash"></i></Button></td>
                                                          <td style={{textAlign: 'center'}}>{ !g.idjugador.startsWith('T') && <Link to={'/jugador/'+g.idjugador} className="mr-1 btn btn-info waves-effect waves-light"><i className="dripicons-user"></i></Link>}</td>
                                                      </tr>
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                  }
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { })(Partido));
