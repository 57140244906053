import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  Alert,  FormGroup, Label, Input, CardHeader} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import CONFIG from '../../CONFIG.json';
import { showLoader, isEmpty, buildBody } from '../../auxiliares/funciones';
import { timeFromDate, dayNameFromDate, monthNameFromDate } from '../../auxiliares/dates';

import { connect } from "react-redux";

class Partidos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:'1',
            partidos:[],
            partidosO:[]
        }
    }

    componentDidMount(){
        showLoader(true);
        this.obtienePartidos()
    }

    buscaTabla = (input) =>{
        const datos = this.state.partidosO.filter(item => {
          return Object.keys(item).some(key =>
            key !== 'id' && item[key].toLowerCase().includes(input.target.value.toLowerCase())
          );
        })
        this.setState({partidos: datos})
    }

    obtienePartidos = () =>{
      /*const partidos = [{
        id:'1',
        fecha: '2021-12-13 17:15',
        tipo: 'Futbol Sala',
        lugar: 'Benicalap',
        jugadores: '9',
        precio: '4',
        activo: '1'
      },{
        id:'2',
        fecha: '2021-12-15 18:45',
        tipo: 'Futbol 7',
        lugar: 'UPV',
        jugadores: '13',
        precio: '4',
        activo: '1'
      }]
      this.setState({partidos: partidos, partidosO: partidos})
      showLoader(false);*/
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
      fetch(CONFIG.api + "partidos/historico",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            this.setState({partidos: result.data, partidosO: result.data})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    render() {
        const {partidos, partidosO } = this.state
        return (
            <React.Fragment>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Histórico de partidos ({partidosO.length})</h4>
                                </CardHeader>
                                <CardBody>
                                  { isEmpty(partidosO)
                                    ? <Alert color="info"><i className="dripicons-information"></i> No tienes ningun partido pasado.</Alert>
                                    :
                                  <div className="table-responsive mt-4">
                                    <FormGroup>
                                        <Label className="control-label">Buscar</Label>
                                        <Input className="form-control width200" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e)} type="text" name="buscar"/>
                                    </FormGroup>
                                    <Table className="table table-hover mb-0 table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th style={{textAlign: 'center'}}></th>
                                                <th>Tipo</th>
                                                <th>Fecha</th>
                                                <th>Hora</th>
                                                <th>Lugar</th>
                                                <th>Jugadores</th>
                                                <th>Precio</th>
                                                <th>Pagos</th>
                                                <th>Cierre</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {partidos.map((g,id)=>{
                                              return <tr key={id}>
                                                            <td><Link to={'/partido/'+g.id} className="mr-1 btn btn-info waves-effect waves-light">Ver partido</Link></td>
                                                            <td>{g.tipo}</td>
                                                            <td style={{fontWeight: 'bold'}}>{dayNameFromDate(g.fecha)+' de '+monthNameFromDate(g.fecha)}</td>
                                                            <td style={{textAlign: 'center',fontWeight: 'bold'}}>{timeFromDate(g.fecha)}</td>
                                                            <td>{g.campo}</td>
                                                            <td style={{textAlign: 'center', backgroundColor:'#45678a', color: 'white'}}>{g.jugadores}</td>
                                                            <td style={{textAlign: 'center'}}>{g.precio} €</td>
                                                            <td style={{textAlign: 'center'}}>{ g.pagado!==g.jugadores ? <span style={{color: 'red'}}>{g.pagado} de {g.jugadores}</span> : <span style={{color: 'green'}}>{g.pagado} de {g.jugadores}</span>}</td>
                                                            <td><Link to={'/partido-cierre/'+g.id} className="mr-1 btn btn-warning waves-effect waves-light"><i className="dripicons-checklist"></i></Link></td>
                                                        </tr>
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { })(Partidos));
