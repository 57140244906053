import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  TabContent,  TabPane,  NavLink,  NavItem,  Nav, Alert,  FormGroup, Label, Input, CardHeader, Button} from "reactstrap";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import CONFIG from '../../CONFIG.json';
import { showLoader, isEmpty, buildBody } from '../../auxiliares/funciones';
import { timeFromDate, dayNameFromDate, monthNameFromDate } from '../../auxiliares/dates';

import { connect } from "react-redux";

class Jugadores extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible:'1',
            jugadores:[],
            jugadoresO:[]
        }
    }

    componentDidMount(){
        showLoader(true);
        this.obtieneJugadores()
    }

    buscaTabla = (input) =>{
        const datos = this.state.jugadoresO.filter(item => {
          return Object.keys(item).some(key =>
            key !== 'id' && item[key].toString().toLowerCase().includes(input.target.value.toLowerCase())
          );
          /*return Object.entries(item).forEach(([key, value]) => {
              item[key].toLowerCase().includes(input.target.value.toLowerCase())
          });*/
        })
        this.setState({jugadores: datos})
    }

    obtieneJugadores = () =>{
      /*const jugadores = [{
        id:'1',
        nombre: 'Pepito Lopez',
        telefono: '633214591',
        grupo: 'B',
        asistencias: '9/9',
        deudas: '4'
      },{
        id:'2',
        nombre: 'Luisito Perez',
        telefono: '635778514',
        grupo: 'A',
        asistencias: '9/9',
        deudas: '0'
      }]
      this.setState({jugadores: jugadores, jugadoresO: jugadores})
      showLoader(false);*/
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{}))
      fetch(CONFIG.api + "jugadores",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            this.setState({jugadores: result.data, jugadoresO: result.data})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    render() {
        const {jugadores, jugadoresO } = this.state
        jugadores.sort((a,b) => a.nombre.localeCompare(b.nombre))

        return (
            <React.Fragment>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">Total jugadores ({jugadoresO.length})</h4>
                                </CardHeader>
                                <CardBody>
                                  <div className="table-responsive mt-4">
                                    <FormGroup>
                                        <Label className="control-label">Buscar</Label>
                                        <Input className="form-control width200" autoComplete="off" placeholder="Buscar en la tabla" onChange={(e)=>this.buscaTabla(e)} type="text" name="buscar"/>
                                    </FormGroup>
                                    <Table className="table table-hover mb-0 table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Teléfono</th>
                                                <th style={{textAlign: 'center'}}>Grupo</th>
                                                <th style={{textAlign: 'center'}}>Asistencias</th>
                                                <th style={{textAlign: 'center'}}>Deudas</th>
                                                <th style={{textAlign: 'center'}}>Detalle</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {jugadores.map((g,id)=>{
                                              return <tr key={id}>
                                                            <td>{g.nombre}</td>
                                                            <td>{g.telefono}</td>
                                                            <td style={{textAlign: 'center'}}>{g.grupo}</td>
                                                            <td style={{textAlign: 'center'}}>{g.asistencias}/{g.asistenciasT}</td>
                                                            <td style={{textAlign: 'center'}}>{g.deudas} €</td>
                                                            <td style={{textAlign: 'center'}}><Link to={'/jugador/'+g.id} className="mr-1 btn btn-info waves-effect waves-light"><i className="dripicons-user"></i></Link></td>
                                                        </tr>
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { })(Jugadores));
