import React, { Component } from 'react';
import {  Table, Col,  Row,  Card,  CardBody,  TabContent,  TabPane,  NavLink,  NavItem,  Nav, Alert,  FormGroup, Label, Input, CardHeader, Button} from "reactstrap";
import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import CONFIG from '../../CONFIG.json';
import Select, { ActionMeta, OnChangeValue } from "react-select";
import CreatableSelect from 'react-select/creatable';
import DatePicker, { registerLocale, setDefaultLocale  } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { showLoader, buildBody, isEmpty, actualizaObjeto } from '../../auxiliares/funciones';
import { timeFromDate, dayNameFromDate, monthNameFromDate, datetimeToStringDatetime, yearFromDate } from '../../auxiliares/dates';

import { connect } from "react-redux";

registerLocale('es', es)

class Jugador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            jugador:{
              nombre: '',
              telefono: '',
              grupo: '',
              notas: ''
            },
            partidos:[],
            campos_form : ['nombre','telefono']
        }
    }

    componentDidMount(){
        showLoader(true);
        if(this.state.id !== undefined){
          this.obtieneJugador(this.state.id)
        }else{
          showLoader(false);
        }
    }

    actualizaPartido = (input,id) =>{
      showLoader(true);
      let obj = { id:id }
      obj[input.target.name] = input.target.value
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,obj))
      fetch(CONFIG.api + "partido/cierre/jugador",{
                method: 'PUT',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            const campo = input.target.name
            const valor = input.target.value
            let res = this.state.partidos
            const ind = res.findIndex(e => e.id === id)

            res[ind][campo] = valor
            this.setState({partidos:res})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    obtieneJugador = (id) =>{
      /*const jugador = {
        nombre: 'Luisito Perez',
        telefono: '635778514',
        grupo: 'A',
        notas: '',
        asistencias: '9/9',
        deudas: 0
      }
      const partidos = [{
        id:'1',
        tipo:'Futbol Sala',
        campo:'Benicalap',
        fecha:'2022-01-10 17:45',
        asistencia:'Asistido',
        pagado:'Pagado',
        notas:''
      },{
        id:'2',
        tipo:'Futbol 7',
        campo:'UPV',
        fecha:'2022-01-11 18:45',
        asistencia:'Falta',
        pagado:'Falta',
        notas:'Me pinchó una pelota'
      }]
      this.setState({jugador: jugador, partidos: partidos})
      showLoader(false);*/
      const body = JSON.stringify(buildBody(CONFIG.TOKEN,{id:id}))
      fetch(CONFIG.api + "jugador",{
                method: 'POST',
                body: body,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
          if (result.error === 0) {
            this.setState({jugador: result.data.jugador, partidos: result.data.partidos})
          } else{
            confirmAlert({
               title: "Error",
               message: result.errorMessage,
               buttons: [{label: 'Ok'}]
             })
          }
          showLoader(false);
      })
    }

    actualizaCampo = (input) =>{
      const nombre = input.target.name
      const respuesta = input.target.value
      let res = this.state.jugador
      res[nombre] = respuesta
      this.setState({jugador:res})
    }

    grabarForm = () =>{
      let res = this.state.jugador
      let camposOK = true
      this.state.campos_form.forEach((c, i) => {
        if(!res[c]){
          camposOK = false
        }
      });
      if(camposOK){
        showLoader(true);
        /*confirmAlert({
           title: "Grabado!",
           buttons: [{label: 'Ok'}]
         })
         const result = {
           id: '1'
         }
         let partido = this.state.partido*/
         const jugador = this.state.jugador
         let jug = {
           id: jugador.id ? jugador.id : null,
           nombre: jugador.nombre,
           telefono: jugador.telefono,
           notas: jugador.notas,
           grupo: jugador.grupo
         }
         const body = JSON.stringify(buildBody(CONFIG.TOKEN,jug))
         fetch(CONFIG.api + "jugador",{
                   method: 'PUT',
                   body: body,
                   headers: {'Content-Type': 'application/json'}
           })
           .then(res => res.json())
           .then((result) => {
             if (result.error === 0) {
               confirmAlert({
                  title: "Grabado!",
                  buttons: [{label: 'Ok'}]
                })
               if(!jugador.id){
                 this.props.history.push({pathname: '/jugador/'+result.data.id});
               }
             } else{
               confirmAlert({
                  title: "Error",
                  message: result.errorMessage,
                  buttons: [{label: 'Ok'}]
                })
             }
             showLoader(false);
         })
    }else{
      confirmAlert({
         title: "Faltan datos",
         message: "Revisa que hayas respondido todas las cuestiones",
         buttons: [{label: 'Ok'}]
       })
    }
  }

    render() {
        const { jugador, partidos } = this.state

        return (
            <React.Fragment>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardHeader>
                                  <h4 className="mb-0">{ jugador.id ? 'Editar jugador' : 'Nuevo jugador'}</h4>
                                </CardHeader>
                                <CardBody>
                                      <Row>
                                        <Col md="4" lg="4" xl="4" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Nombre</Label>
                                            <Input className="form-control" value={jugador.nombre} onChange={(e)=>this.actualizaCampo(e)} type="text" name="nombre"/>
                                        </FormGroup>
                                      </Col>
                                        <Col md="2" lg="2" xl="2" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Teléfono</Label>
                                            <Input className="form-control" value={jugador.telefono} onChange={(e)=>this.actualizaCampo(e)} type="text" name="telefono"/>
                                        </FormGroup>
                                      </Col>
                                      <Col md="2" lg="2" xl="2" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Grupo</Label>
                                            <Input className="form-control" value={jugador.grupo} onChange={(e)=>this.actualizaCampo(e)} type="text" name="grupo"/>
                                        </FormGroup>
                                      </Col>
                                      <Col md="4" lg="4" xl="4" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Notas</Label>
                                            <Input className="form-control" value={jugador.notas} onChange={(e)=>this.actualizaCampo(e)} type="text" name="notas"/>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md="2" lg="2" xl="2"><br/>
                                          <Button onClick={()=>this.grabarForm()} color="success" className="w-md waves-effect waves-light" type="button">Grabar jugador</Button>
                                      </Col>
                                      <Col md="2" lg="2" xl="2"><br/>
                                          <Link to={'/jugador'} className="mr-1 btn btn-info waves-effect waves-light">Nuevo jugador</Link>
                                      </Col>
                                      { this.state.id  &&
                                      <Col md="2" lg="2" xl="2" className="mt-4">
                                        <FormGroup>
                                            <Label className="control-label">Asistencias: {jugador.asistencias}/{jugador.asistenciasT} </Label>
                                        </FormGroup>
                                      </Col>
                                      }
                                      { this.state.id  &&
                                      <Col md="2" lg="2" xl="2" className="mt-4">
                                          <FormGroup>
                                              <Label className="control-label">Deudas: {jugador.deudas} € </Label>
                                          </FormGroup>
                                      </Col>
                                      }
                                    </Row>
                                </CardBody>
                                <CardHeader>
                                  <h4 className="mb-0">Histórico de partidos { !isEmpty(partidos) && '('+partidos.length+')' }</h4>
                                </CardHeader>
                                <CardBody>
                                  { isEmpty(partidos) ?
                                    <Alert color="info"><i className="dripicons-information"></i> Este jugador no ha participado en ningun partido.</Alert>
                                  :
                                  <div className="table-responsive mt-4" style={{paddingBottom:'100px'}}>
                                    <Table className="table table-hover mb-0 table-bordered">
                                        <thead className="thead-light">
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Campo</th>
                                                <th>Fecha</th>
                                                <th>Nota</th>
                                                <th style={{textAlign: 'center'}}>Asistencia</th>
                                                <th style={{textAlign: 'center'}}>Pago</th>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {partidos.map((g,id)=>{
                                              return <tr key={id}>
                                                          <td>{g.tipo}</td>
                                                          <td>{g.campo}</td>
                                                          <td>{dayNameFromDate(g.fecha)+' de '+monthNameFromDate(g.fecha)+' , '+yearFromDate(g.fecha)}</td>
                                                          <td>{g.notas}</td>
                                                          <td style={{textAlign: 'center'}}><Button onClick={()=>this.actualizaPartido({target:{name:'asistencia',value:g.asistencia === 0 ? 1 : 0}},g.id)} color={g.asistencia === 1 ? "success" : "danger"} className="waves-effect waves-light" type="button">{g.asistencia === 1 ? "Asistido" : "Falta"}</Button></td>
                                                          <td style={{textAlign: 'center'}}><Button onClick={()=>this.actualizaPartido({target:{name:'pagado',value:g.pagado === 0 ? 1 : 0}},g.id)} color={g.pagado === 1 ? "success" : "danger"} className="waves-effect waves-light" type="button">{g.pagado === 1 ? "Pagado" : "Falta"}</Button></td>
                                                          <td style={{textAlign: 'center'}}><Link to={'/partido/'+g.idpartido} className="mr-1 btn btn-info waves-effect waves-light">Ver</Link></td>
                                                          <td style={{textAlign: 'center'}}><Link to={'/partido-cierre/'+g.idpartido} className="mr-1 btn btn-warning waves-effect waves-light">Cerrar</Link></td>
                                                      </tr>
                                          })}
                                        </tbody>
                                      </Table>
                                    </div>
                                  }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { })(Jugador));
